import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useState,
  useEffect,
  // useMemo,
} from "react";
import classNames from "classnames";
import { TextareaAutosizeProps } from "react-textarea-autosize";

import { UploadIcon } from "../icons/UploadIcon";
import styles from "./docUpload.module.scss";
import Dropdown from "rc-dropdown";
import Menu from "rc-menu";

import { useSelector } from "redux/hooks";
import { DocUrl } from "../icons/DocUrl";
import { UploadCom } from "../icons/UploadCom";
import { useIntl } from "react-intl";
import { UploadURL } from "./UploadURL";

import {
  IChat,
  setChatModel,
  ChatType,
  IChatModel,
} from "redux/actions";
import {
  IErrorMessage,
  IFileErrorState,
} from "pages/ChatPage/pages/newChat/NewChat";
import { ErrorModal } from "pages/ChatPage/pages/newChat/components/ErrorModal";
import useRouter from "hooks/useRouter";

import { validateFile, validateImgFile } from "utils/fileService";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { convertFileNamesToLowercase, ErrorMessages } from "utils/functions";
import { RoutePaths } from "pages/routePaths";
import { FileSizeLimitModal } from "../fileSizeLimitModal";
import { useFileUploader } from "utils/fileUploadS3";
import { supportedModels } from "utils/constants";

interface IProps extends TextareaAutosizeProps {
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  selectedFile?: File[] | null;
  setUploadUrl?: Dispatch<SetStateAction<boolean>>;
  resetMessage?: () => void;
  message?: string;
  url?: string;
  isloading?: boolean;
  setURL?: Dispatch<SetStateAction<string>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
}

interface Options {
  Icon: any;
  title: string;
}

export const DocUpload = ({
  setChatHistory,
  chatHistoryPagination,
  setSelectedFile,
  setIsMainScreenOpen,
  selectedFile,
  message,
  resetMessage,
  setUploadUrl,
  url,
  setURL,
  onSendMessage,
  setOpenHistory,
  setUploadingFiles,
  setFileS3Link,
  setIsFileUploading,
  uploadingFiles,
  isAllowUploadFile,
  chatItem,
  isloading,
}: IProps) => {
  const { theme, gptModel, userDetail } = useSelector(
    (state) => state.authReducer
  );
  const { PrivateChat } = useSelector((state) => state.chatReducer);
  const { newMessages, messages } = useSelector((state) => state.chatReducer);
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const { pathname, push } = useRouter();
  const [uploadURL, setISUploadURL] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [errorModal, setErrorModal] = useState<IFileErrorState>({
    message: "",
    show: false,
  });
  const [changeModel, setChangeModel] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
  const history = pathname.includes("/chat/history");
  const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
  const { uploadToS3 } = useFileUploader();

  const docUploadOptions: Options[] = [
    {
      Icon: DocUrl,
      title: formatMessage({ id: "doc.upload.url" }),
    },
    {
      Icon: UploadCom,
      title: formatMessage({ id: "doc.upload.computer" }),
    },
  ];

  const documentMaxCountReached = (): boolean => {
    const max_count = currentPlan?.attributes?.max_document_chats as number;
    return (
      (userDetail?.user.activeSubscription.document_chat_count as number) >=
      max_count
    );
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsMainScreenOpen!(true);
    setOpenHistory!(false);

    if (!gptModel?.type?.includes(ChatType.document)) {
      const model = localStorage.getItem('documentChatGptModel');
      let selectedModel = model ? JSON.parse(model) : chatModels[0];
      if (model) {
        localStorage.setItem('GptModel', model);
      }
      else {
        localStorage.setItem('documentChatGptModel', JSON.stringify(chatModels[0]));
        localStorage.setItem('GptModel', JSON.stringify(chatModels[0]));
      }
      setChatModel(selectedModel)
    }

    const file = event.target.files || null;
    if (file) {
      const newFilesArray = Array.from(file);
      const imageFiles = newFilesArray.filter((file) =>
        file.type.startsWith("image/")
      );
      const otherFiles = newFilesArray.filter(
        (file) => !file.type.startsWith("image/")
      );

      if (
        ((newMessages.length > 0 &&
          newMessages[0]?.images &&
          newMessages[0]?.images.length > 0) ||
          (chatItem && chatItem.chat_type === "image_chat" && history)) &&
        otherFiles.length > 0
      ) {
        setErrorModal({
          // message: " Document can't be selected for image chat",
          message: formatMessage({id: "upload.file.validation.docURL"}),
          show: true,
        });
      } else if (
        ((newMessages.length > 0 &&
          newMessages &&
          (newMessages[0]?.files?.length ?? 0 > 0)) ||
          (chatItem && chatItem.chat_type === "document" && history)) &&
        imageFiles.length > 0
      ) {
        setErrorModal({
          // message: " Image can't be selected for document chat",
          message: formatMessage({id: "upload.file.validation.imgURL"}),
          show: true,
        });
      } else if (
        (imageFiles.length > 0 && otherFiles.length > 0) ||
        (uploadingFiles &&
          uploadingFiles.length > 0 &&
          uploadingFiles[0]?.file.type.startsWith("image/") &&
          otherFiles.length > 0) ||
        (uploadingFiles &&
          uploadingFiles.length > 0 &&
          !uploadingFiles[0]?.file.type.startsWith("image/") &&
          imageFiles.length > 0)
      ) {
        setErrorModal({
          // message:
          //   "Images and documents can't be selected together. Please select one type at a time.",
          message: formatMessage({id: "upload.file.validation.combine"}),
          show: true,
        });
      } else if (imageFiles.length > 0) {
        if (changeModel && gptModel?.name && !supportedModels.includes(gptModel?.name)) {
          const imageChatModel = localStorage.getItem('imageChatGptModel');
          if (imageChatModel) {
            localStorage.setItem('GptModel', imageChatModel);
            setChatModel(JSON.parse(imageChatModel));
          }
        }
        setSelectedFile!((prevSelectedFiles) => {
          const combinedFiles = prevSelectedFiles
            ? [...prevSelectedFiles, ...imageFiles]
            : imageFiles;
          if (
            file &&
            file[0].type.startsWith("image/") &&
            !gptModel?.type.includes("image_chat")
          ) {
            if (gptModel?.name && !supportedModels.includes(gptModel?.name)) {
              const imageChatModel = localStorage.getItem('imageChatGptModel');
              const selectedModal = imageChatModel ? JSON.parse(imageChatModel) : (chatModels[1]);
              if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
              }
              else {
                localStorage.setItem('GptModel', JSON.stringify(chatModels[1]));
                localStorage.setItem('imageChatGptModel', JSON.stringify(chatModels[1]));
              }
              setChatModel(selectedModal)
            }
          }
          const total =
            (uploadingFiles ? uploadingFiles?.length : 0) + imageFiles.length;
          if (total > 20) {
            const firstThreeFiles = combinedFiles.slice(0, 20);
            setErrorModal({
              message: "ImageChat.file.fileLimit",
              show: true,
            });
            return firstThreeFiles;
          } else {
            return combinedFiles;
          }
        });
        setChangeModel(false);
        const imgFile = convertFileNamesToLowercase(imageFiles);
        await uploadFilesToS3(imgFile);
      } else if (otherFiles.length > 0) {
        if (documentMaxCountReached()) {
          setMessageId!("documentChat.plan.max_count");
          event.target.value = "";
          setSelectedFile!(null);
          setUploadingFiles!([]);
          setIsMainScreenOpen!(true);
          return;
        }
        const totalFilesCount = (uploadingFiles ? uploadingFiles.length : 0) + otherFiles.length;
        const isFreePlan = userDetail?.user.activeSubscription?.name === 'Free';
        if (isFreePlan && totalFilesCount > 1) {
          setMessageId!("documentChat.plan.max_count");
          event.target.value = "";
          return;
        }
        if (
          otherFiles.length > 5 ||
          (uploadingFiles &&
            !uploadingFiles[0]?.file.type.startsWith("image/") &&
            (uploadingFiles?.length > 5 || totalFilesCount > 5))
        ) {
          setErrorModal({
            message: "documentChat.file.fileLimit",
            show: true,
          });
        } else {
          setSelectedFile!((prevSelectedFiles) => {
            const combinedFiles = prevSelectedFiles
              ? [...prevSelectedFiles, ...otherFiles]
              : otherFiles;
            return combinedFiles;
          });
          await uploadFilesToS3(otherFiles);
        }
      }
    }

    event.target.value = "";
  };

  const AllowedFileTypes = [
    "pdf",
    "txt",
    "ppt",
    "pptx",
    // "doc",
    "docx",
    "csv",
    "xls",
    "xlsx",
    "eml",
    "srt",
    "png",
    "jpg",
    "jpeg",
    "webp",
    "gif",
  ];
  const acceptString = AllowedFileTypes.map((val) => ` .${val}`).toString();

  const handleDocUploadURL = () => {
    if (
      uploadingFiles === null ||
      (uploadingFiles && uploadingFiles.length === 0)
    ) {
      setISUploadURL(true);
      setSelectedFile!(null);
      if (
        (newMessages[0]?.images?.length === 0 ||
          messages[0]?.images?.length === 0) &&
        !history
      )
        setIsMainScreenOpen!(true);
      if (!gptModel?.type.includes('image_chat') && gptModel?.name) {
        if (!supportedModels.includes(gptModel?.name))
          setChangeModel(true);
      }
      if (!gptModel?.type?.includes(ChatType.document)) {
        const model = localStorage.getItem('documentChatGptModel');
        let selectedModel = model ? JSON.parse(model) : chatModels[0];
        if (model) {
          localStorage.setItem('GptModel', model);
        }
        else {
          localStorage.setItem('documentChatGptModel', JSON.stringify(chatModels[0]));
          localStorage.setItem('GptModel', JSON.stringify(chatModels[0]));
        }
        setChatModel(selectedModel);
      }
    }
  };

  const onVisibleChange = (visable: boolean) => {
    setVisible(visable);
  };

  useEffect(() => {
    ErrorMessages(errorMessage, setErrorModal);
  }, [errorMessage]);

  useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [selectedFile]);

  const uploadFilesToS3 = async (files: File[]) => {
    if (!files[0].type.startsWith("image/")) {
      if (documentMaxCountReached()) {
        setMessageId!("documentChat.plan.max_count");
        setSelectedFile!(null);
        setUploadingFiles!([]);
        setIsMainScreenOpen!(true);
        return;
      }
    }

    const fileArray = Array.from(files);
    const fileType = files[0]?.type.startsWith("image/") ? "image" : "document"
    const validFiles =
      fileType === "image"
        ? validateImgFile(
          fileArray,
          setErrorMessage,
          claudAllowedFileSize,
          formatMessage
        )
        : validateFile(
          fileArray,
          setIsMainScreenOpen,
          setErrorModal,
          userDetail,
          setMessageId,
        );

    if (validFiles) {
      setIsFileUploading!(true);
      await uploadToS3({ fileArray, setUploadingFiles, fileType, setIsFileUploading, setFileS3Link });
    }
  };

  const onConfirm = () => {
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
  };
  const onCancel = () => {
    setMessageId("");
  };

  return (
    <>
      {!isAllowUploadFile ? (
        <div>
          <div className={classNames(styles.uploadIcon, {
            [styles.privateuploadIcon]: PrivateChat,
          })}>
            <UploadIcon isPrivateChat={PrivateChat} />
          </div>
        </div>
      ) : (
        <Dropdown
          trigger={["click"]}
          visible={visible}
          onVisibleChange={onVisibleChange}
          overlay={
            <Menu
              style={{
                width: "250px",
                height: "auto",
                maxHeight: "250px",
                borderRadius: "10px",
                border: "none",
                backgroundColor: theme === "dark" ? "#252526" : "#ffffff",
                backdropFilter: "blur(25px)",
                boxShadow: " 0px 8px 18px 0px rgba(120, 120, 120, 0.1)",
                overflow: "hidden",
                position: "relative",
                marginLeft: "-70px",
                marginBottom: "20px",
              }}
            >
              <div className={styles.DropDownbody}>
                {docUploadOptions.map((option, index) => (
                  <>
                    <label htmlFor={index === 1 ? "file-input" : ""}>
                      <div
                        className={classNames(
                          `flex gap-4 justify-center items-center hover:rounded-md hover:cursor-pointer hover:text-primary hover:bg-[rgba(124,77,255,0.08)] px-[22px] py-2  `,
                          {

                            [styles.disabled]:
                              ((uploadingFiles !== null &&
                                uploadingFiles &&
                                uploadingFiles?.length > 0) ||
                                isloading) &&
                              option.Icon === DocUrl,

                          }
                        )}
                        data-testid="select-upload-option"
                        onClick={
                          option.Icon === DocUrl
                            ? () => handleDocUploadURL()
                            : () => {
                              setISUploadURL(false);
                              setURL!("");
                              if (!gptModel?.type.includes('image_chat')) setChangeModel(true);
                            }
                        }
                      >
                        <div className={styles.imagedropdownContainer}>
                          <option.Icon />
                        </div>
                        <div className={styles.textContainer}>
                          <div
                            className={classNames(styles.dropdownModalName, {
                              [styles.dark]: theme === "dark",
                              [styles.light]: theme === "light",
                            })}
                          >
                            {option.title}
                          </div>
                        </div>
                      </div>
                    </label>
                    {index !== docUploadOptions.length - 1 && (
                      <div className={styles.svg} />
                    )}
                  </>
                ))}
              </div>
            </Menu>
          }
        >
          <div>
            <div>
              <span className="cursor-pointer">
                <UploadIcon isPrivateChat={PrivateChat} />
              </span>
            </div>
          </div>
        </Dropdown>
      )}

      {uploadURL && (
        <UploadURL
          setISUploadURL={setISUploadURL}
          setChatHistory={setChatHistory}
          chatHistoryPagination={chatHistoryPagination}
          setErrorModal={setErrorModal}
          setUploadUrl={setUploadUrl}
          message={message}
          resetMessage={resetMessage}
          url={url}
          setURL={setURL}
          onSendMessage={onSendMessage}
          setMessageId={setMessageId}
          changeModel={changeModel}
          setChangeModel={setChangeModel}
        />
      )}

      <input
        id="file-input"
        type="file"
        style={{ display: "none" }}
        accept={acceptString}
        multiple={true}
        onChange={handleFileChange}
      />
      {errorModal.show === true && (
        <div className={styles.containerCenter}>
          <div
            className={classNames(styles.popupContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <ErrorModal
              uploadURL={true}
              message={errorModal.message}
              onClose={() => {
                setErrorModal({ message: "", show: false });
              }}
            />
          </div>
        </div>
      )}

      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};