import React, { Dispatch, SetStateAction, memo, useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import classNames from "classnames";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";
import { useSelector } from "redux/hooks";
import { SendButton } from "../sendButton";
import { EChatType, IChat, IChatModel } from "redux/actions";
import { DocUpload } from "../DocumentUpload";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { FileUploadPreview } from "./FileUploadPreview";
import { validateFile, validateImgFile } from "utils/fileService";

import { useFileUploader } from "utils/fileUploadS3";
import { FileSizeLimitModal } from "../fileSizeLimitModal";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages";
import { convertFileNamesToLowercase, ErrorMessages, handleModelSwitch } from "utils/functions";

interface IProps extends TextareaAutosizeProps {
  isloading?: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isFieldFocused?: boolean;
  onTextareaFocus?: () => void;
  isFileUploading?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  updateChatModel?: (modalType: EChatType) => void;
  selectedFile?: File[] | null;
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setUploadUrl?: Dispatch<SetStateAction<boolean>>;
  resetMessage?: () => void;
  message?: string;
  url?: string;
  setURL?: Dispatch<SetStateAction<string>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  getMessagesLoading: boolean ;
}

const Spinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 18px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const SearchField = memo(
  ({
    isloading,
    textareaRef,
    isFieldFocused,
    onTextareaFocus,
    isFileUploading,
    handleSubmit,
    updateChatModel,
    selectedFile,
    setSelectedFile,
    setChatHistory,
    chatHistoryPagination,
    setIsMainScreenOpen,
    setUploadUrl,
    resetMessage,
    message,
    url,
    setURL,
    onSendMessage,
    setOpenHistory,
    uploadingFiles,
    setUploadingFiles,
    setFileS3Link,
    setIsFileUploading,
    isAllowUploadFile,
    chatItem,
    setErrorModal,
    getMessagesLoading,
    ...props
  }: IProps) => {
    const { theme, userDetail, gptModel } = useSelector((state) => state.authReducer);
    const currentPlan = useSelector((state) => state.planSubscriptionReducer.activePlan);
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { newMessages } = useSelector((state) => state.chatReducer);
    const { PrivateChat} = useSelector((state) => state.chatReducer);
    const { formatMessage } = useIntl();
    const { uploadToS3 } = useFileUploader();
    const { push, pathname } = useRouter();
    const history = pathname.includes("/chat/history");

    const [messageId, setMessageId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);

    useEffect(() => {
      ErrorMessages(errorMessage, setErrorModal)
    }, [errorMessage])

    const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (textareaRef.current) textareaRef.current.focus();
    };

    const documentMaxCountReached = (): boolean => {
      const max_count = currentPlan?.attributes?.max_document_chats as number;
      return (
        (userDetail?.user.activeSubscription.document_chat_count as number) >=
        max_count
      );
    };

    const handlePaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      setOpenHistory!(false);
      const items = event.clipboardData.items;
      const files: File[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            if (!gptModel?.type?.includes('document') || history && chatItem && chatItem.chat_type === "text") {
            }
            else
              if (((newMessages.length === 0) || (newMessages[0]?.files && newMessages[0]?.files.length > 0) || (newMessages[0]?.images && newMessages[0]?.images.length > 0)) || (chatItem && chatItem.chat_type === "image_chat"))
                files.push(file);
          }
        }
      }

      if (files.length > 0) {
        const isImageFile = files[0].type.startsWith("image/");
        const imgFiles = isImageFile ? convertFileNamesToLowercase(files) : files;
        await uploadFilesToS3(imgFiles);
      }
    };

    {/* /////////////////Validate File///////////////*/ }

    const validateFileSelection = useCallback((files: File[], hasSelectedImage: boolean | undefined, hasSelectedDocument: boolean | undefined) => {
      const isImageFile = files[0].type.startsWith("image/");
      const imageFiles = files.filter(file => file.type.startsWith("image/"));
      const documentFiles = files.filter(file => !file.type.startsWith("image/"));

      if ((isImageFile && hasSelectedDocument) || (!isImageFile && hasSelectedImage) || (imageFiles.length > 0 && documentFiles.length > 0)) {
        setErrorModal({
          // message: "Images and documents can't be selected together. Please select one type at a time.",
          message: formatMessage({id: "upload.file.validation.combine"}),
          show: true,
        });
        return false;
      }

      if (((newMessages[0]?.images?.length || 0) > 0 || (chatItem?.chat_type === "image_chat" && history)) && documentFiles.length > 0) {
        setErrorModal({
          // message: "Document can't be selected for image chat",
          message: formatMessage({id: "upload.file.validation.docURL"}),
          show: true,
        });
        return false;
      }

      if (((newMessages[0]?.files?.length || 0) > 0 || (chatItem?.chat_type === "document" && history)) && imageFiles.length > 0) {
        setErrorModal({
          // message: "Image can't be selected for document chat",
          message: formatMessage({id: "upload.file.validation.imgURL"}),
          show: true,
        });
        return false;
      }                  
  
      const totalFilesCount = (uploadingFiles?.length || 0) + files.length;
      const isFreePlan = userDetail?.user.activeSubscription?.name === 'Free';
      if (isFreePlan && totalFilesCount > 1 && !isImageFile) {
        setMessageId!("documentChat.plan.max_count");
        return;
      }
      if ((!isImageFile && totalFilesCount > 5) || (isImageFile && totalFilesCount > 20)) {
        setErrorModal({
          message: isImageFile ? "ImageChat.file.fileLimit" : "documentChat.file.fileLimit",
          show: true,
        });
        return false;
      }

      return true;
    }, [uploadingFiles, newMessages, chatItem, setErrorModal]);

    {/* /////////////////Upload To S3///////////////*/ }

    const uploadFilesToS3 = async (files: File[]) => {
      const isImageFile = files[0].type.startsWith("image/");
      if (!files[0].type.startsWith("image/")) {
        if (documentMaxCountReached()) {
          setMessageId!("documentChat.plan.max_count");
          setSelectedFile!(null);
          setUploadingFiles!([]);
          setIsMainScreenOpen!(true);
          return;
        }
      }
      handleModelSwitch(gptModel, chatModels, isImageFile)
      const hasSelectedImage = uploadingFiles && uploadingFiles.some(file => file.file.type.startsWith("image/"));
      const hasSelectedDocument = uploadingFiles && uploadingFiles.some(file => !file.file.type.startsWith("image/"));

      if (!validateFileSelection(files, hasSelectedImage, hasSelectedDocument)) return;

      const fileArray = Array.from(files);
      const fileType = files[0]?.type.startsWith("image/")
        ? "image"
        : "document";
      const validFiles = fileType === "image"
        ? validateImgFile(
          fileArray,
          setErrorMessage,
          claudAllowedFileSize,
          formatMessage
        )
        : validateFile(
          fileArray,
          setIsMainScreenOpen,
          setErrorModal,
          userDetail,
          setMessageId,
        );

      if (validFiles) {
        setIsFileUploading!(true);
        await uploadToS3({ fileArray, setUploadingFiles, fileType, setIsFileUploading, setFileS3Link });
      }
    };

    const onConfirm = () =>
      push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
    const onCancel = () => setMessageId("");

    return (
      <>
        <div
          className={classNames(styles.textAreaContainer, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.activeborder]: true,
            [styles.file]: uploadingFiles && uploadingFiles.length > 0,
            [styles.Privateborder]: PrivateChat,
          })}
          onClick={() => {
            if (textareaRef.current) textareaRef.current.focus();
            onTextareaFocus?.();
          }}
        >
          <FileUploadPreview
            uploadingFiles={uploadingFiles}
            selectedFile={selectedFile}
            setUploadingFiles={setUploadingFiles}
            setFileS3Link={setFileS3Link}
          />

          <div className={`flex w-[100%] `}>
            <div className="items-end flex mb-1">
              <DocUpload
                setSelectedFile={setSelectedFile}
                chatHistoryPagination={chatHistoryPagination}
                setChatHistory={setChatHistory}
                setIsMainScreenOpen={setIsMainScreenOpen}
                selectedFile={selectedFile}
                setUploadUrl={setUploadUrl}
                resetMessage={resetMessage}
                message={message}
                url={url}
                setURL={setURL}
                onSendMessage={onSendMessage}
                setOpenHistory={setOpenHistory}
                setUploadingFiles={setUploadingFiles}
                setFileS3Link={setFileS3Link}
                setIsFileUploading={setIsFileUploading}
                uploadingFiles={uploadingFiles}
                isAllowUploadFile={isAllowUploadFile}
                chatItem={chatItem}
                isloading={ isloading}
              />
            </div>
            <TextareaAutosize
              ref={textareaRef}
              className={classNames(styles.textarea, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
                [styles.Privatetextarea]: PrivateChat,
              })}
              minRows={1}
              maxRows={25}
              placeholder={formatMessage({
                id: "chatpage.searchField.placeholder",
              })}
              readOnly={getMessagesLoading} 
              onPaste={handlePaste}
              {...props}
            />
            {isloading && (
              <div className={styles.loaderContainer}>
                <Spinner icon={faCircleNotch} />
              </div>
            )}
            <div className={styles.chatfooterbtn}>
              <SendButton
                isFieldFocused={true}
                disabled={isFileUploading}
                data-testid="chat-footer-btn"
                onMouseDown={handleMouseDown}
                onClick={(event) => handleSubmit && handleSubmit(event)}
              />
            </div>
          </div>
        </div>
        {messageId && (
          <FileSizeLimitModal
            messageId={messageId}
            onCancel={onCancel}
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </>
    );
  }
)